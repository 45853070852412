import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import './styles.scss'

const Card = loadable(() => import('/src/components/Cards/Card'))

TimeAgo.addDefaultLocale(en)

const TrustpilotReviewsWidget = () => {
    const [reviewData, setReviewData] = useState()

    useEffect(() => {
        fetch(
            `https://api.trustpilot.com/v1/business-units/${process.env.GATSBY_TRUSTPILOT_BUSINESS_UNIT_ID}/reviews?apikey=${process.env.GATSBY_TRUSTPILOT_API_KEY}`
        )
            .then((response) => response.json())
            .then((resultData) => {
                const trustpilotData = resultData.reviews
                const slicedData = trustpilotData.slice(0, 3)
                setReviewData(slicedData)
            })
    }, [])

    const reviews = reviewData || []

    return (
        <div className="row c-trustpilot-reviews">
            {reviews.map((node, index) => {
                const timeAgo = Date.parse(node.createdAt)
                const limitText = `${node.text.substring(0, 200)}...`

                return (
                    node.stars >= 3 && ( // Only show reviews if they are more than 3 stars
                        <div
                            key={index}
                            className="col-lg-4 c-trustpilot-reviews__item"
                        >
                            <Card>
                                {node.stars === 5 ? (
                                    <StaticImage
                                        src={
                                            '../../../images/trustpilot-rating-5star.png'
                                        }
                                        width={150}
                                        alt={'Trustpilot 5 star rating'}
                                    />
                                ) : node.stars === 4 ? (
                                    <StaticImage
                                        src={
                                            '../../../images/trustpilot-rating-4star.png'
                                        }
                                        width={150}
                                        alt={'Trustpilot 4 star rating'}
                                    />
                                ) : (
                                    <StaticImage
                                        src={
                                            '../../../images/trustpilot-rating-3star.png'
                                        }
                                        width={150}
                                        alt={'Trustpilot 3 star rating'}
                                    />
                                )}

                                <span className="c-trustpilot-reviews__item-time">
                                    <ReactTimeAgo
                                        date={timeAgo}
                                        locale="en-GB"
                                    />
                                </span>

                                <blockquote className="c-trustpilot-reviews__item-quote">
                                    <h3 className="c-trustpilot-reviews__item-title">
                                        {node.title}
                                    </h3>
                                    <p className="c-trustpilot-reviews__item-content">
                                        {limitText}
                                    </p>
                                    <footer className="c-trustpilot-reviews__item-author">
                                        {node.consumer.displayName}
                                    </footer>
                                </blockquote>
                            </Card>
                        </div>
                    )
                )
            })}
        </div>
    )
}

export default TrustpilotReviewsWidget
